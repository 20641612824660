import styled from "@emotion/styled"
import React from "react"
import colors, { foregroundColors } from "../styles/colors"
import { BORDER_RADIUS_SMALL } from "../styles/constants"
import { typographyStyles } from "./typography"

const Wrapper = styled.div<{ variant: string }>`
  display: flex;
  width: 100%;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  border-radius: ${BORDER_RADIUS_SMALL}px;
  background: ${p => colors[p.variant || "light"]};
  color: ${p => foregroundColors[p.variant || "light"]};

  &:focus-within {
    border-color: #2f80ed;
  }
`

const Select = styled.select`
  ${typographyStyles.bodySmall};
  border-radius: ${BORDER_RADIUS_SMALL}px;
  padding: 16px;
  border: none;
  width: 95%;
  outline: 0;
`

interface InputSelecProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  children: React.ReactNode
}

export const InputSelect = ({
  children,
  ...props
}: InputSelecProps): JSX.Element => {
  return (
    <Wrapper variant="white">
      <Select {...props}>{children}</Select>
    </Wrapper>
  )
}
