import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FeatureWidget } from "../components/widgets/FeatureWidget"
import { FullWIdthTextWidget } from "../components/widgets/FullWidthTextWidget"
import { HeroWidget } from "../components/widgets/HeroWidget"
import { PartnersWidget } from "../components/widgets/PartnersWidget"
import { QuoteWidget } from "../components/widgets/QuoteWidget"
import { SideBySideWidget } from "../components/widgets/SideBySideWidget"
import { StatsWidget } from "../components/widgets/StatsWidget"
import { CardPreviewWidget } from "../components/widgets/CardPreviewWidget"
import { VerticalTextWidget } from "../components/widgets/VerticalTextWidget"
import { Widget } from "../types/widget"
import mavenoidHelpCenter from "../utils/mavenoidHelpCenter"

type ContentfulPage = {
  contentfulPage: {
    title: string | null
    slug: string
    noindex: boolean
    description: string | null
    widgets: Widget[]
    showNewsletter: boolean
  } | null
}

interface LandingPageProps {
  data: ContentfulPage
  pageContext: string
}

const LandingPage = ({ data }: LandingPageProps): JSX.Element => {
  const {
    title,
    description,
    noindex,
    widgets,
    showNewsletter,
  } = data.contentfulPage

  React.useEffect(() => {
    if (data?.contentfulPage?.slug === "accelerator-program") {
      mavenoidHelpCenter(
        "7dba270ddd522990a2aa",
        "https://app.mavenoid.com",
        true
      )
      // @ts-expect-error `window.mavenoid` is untyped
      window.mavenoid.push({
        productId: "5750916",
        orgName: "Accelerator Program",
        sessionId: "new",
        openByDefault: true,
      })
    }
  }, [data.contentfulPage.slug])

  return (
    <Layout newsletter={showNewsletter ? undefined : null}>
      <SEO
        title={title}
        description={description}
        meta={noindex ? [{ name: "robots", content: "noindex" }] : []}
      />
      {widgets?.map(widget => {
        switch (widget.__typename) {
          case "ContentfulHeroWidget": {
            return <HeroWidget key={widget.title} widget={widget} />
          }
          case "ContentfulSideBySideWidget":
            return <SideBySideWidget key={widget.title} widget={widget} />
          case "ContentfulPartnersWidget":
            return <PartnersWidget key={widget.title} widget={widget} />
          case "ContentfulFullWidthTextWidget":
            return <FullWIdthTextWidget key={widget.title} widget={widget} />
          case "ContentfulStatsWidget":
            return <StatsWidget key={widget.title} widget={widget} />
          case "ContentfulVerticalTextWidget":
            return <VerticalTextWidget key={widget.title} widget={widget} />
          case "ContentfulFeatureWidget":
            return <FeatureWidget key={widget.title} widget={widget} />
          case "ContentfulCardPreviewWidget":
            return <CardPreviewWidget key={widget.title} widget={widget} />
          case "ContentfulQuoteWidget":
            return (
              <QuoteWidget
                key={widget.author.childMarkdownRemark.html}
                widget={widget}
              />
            )
          default:
            return null
        }
      })}
    </Layout>
  )
}

export default LandingPage

export const query = graphql`
  query Page($contentfulId: String!) {
    contentfulPage(contentful_id: { eq: $contentfulId }) {
      title
      slug
      description
      noindex
      showNewsletter
      widgets {
        __typename
        ... on ContentfulHeroWidget {
          ...HeroWidget
        }
        ... on ContentfulSideBySideWidget {
          ...SideBySideWidget
        }
        ... on ContentfulPartnersWidget {
          ...PartnersWidget
        }
        ... on ContentfulFullWidthTextWidget {
          ...FullWidthTextWidget
        }
        ... on ContentfulStatsWidget {
          ...StatsWidget
        }
        ... on ContentfulVerticalTextWidget {
          ...VerticalTextWidget
        }
        ... on ContentfulFeatureWidget {
          ...FeatureWidget
        }
        ... on ContentfulCardPreviewWidget {
          ...CardPreviewWidget
        }
        ... on ContentfulQuoteWidget {
          ...QuoteWidget
        }
      }
    }
  }
`
