import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"
import colors from "../../styles/colors"
import { IWidgetProps, WidgetPartners } from "../../types/widget"
import { ButtonWithArrow } from "../button"
import { CardContentWrapper, CardTitle, CardWrapper } from "../card"
import { MobileMediaQuery } from "../../styles/constants"
import { ContentfulImage } from "../ContentfulImage"

const Content = styled(CardContentWrapper)`
  padding-top: 70px;
  padding-bottom: 70px;
`

const Partner = styled(ContentfulImage)`
  display: flex;
  align-items: center;
  width: 150px;
  margin: 0 20px 20px;

  &:first-of-type {
    margin: 0 20px 20px 0;
  }

  &:last-of-type {
    margin: 0 0 20px 20px;
  }
`

const PartnersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 100%;
  margin: 1rem 0;
  ${MobileMediaQuery} {
    flex-wrap: wrap;
    padding-top: 20px;
  }
`

const Button = styled(ButtonWithArrow)`
  height: 50px;
  align-self: center;
  &:hover {
    background-color: ${colors.darkgrey};
    border-color: ${colors.darkgrey};
    color: ${colors.white};
  }
`

const getResizes = (resizes: string | null, logosLength: number) => {
  if (!resizes) return null
  const resizesArray = resizes.split(",")
  if (logosLength !== resizesArray.length) return null

  return resizesArray
}

export const PartnersWidget = ({
  widget,
}: IWidgetProps<WidgetPartners>): JSX.Element => {
  const resizesArray = getResizes(widget.logoResizes, widget.logos.length)

  return (
    <CardWrapper variant="white">
      <Content noMinHeight center>
        <CardTitle variant="h3">{widget.title}</CardTitle>
        <PartnersWrapper>
          {widget.logos.map((logo, index) => {
            return (
              <Partner
                key={logo.title}
                image={logo}
                maxWidth={
                  resizesArray ? parseInt(resizesArray[index]) : undefined
                }
              />
            )
          })}
        </PartnersWrapper>
        {widget.cta ? (
          <Button
            variant="white"
            to={`/${widget.cta.url}`}
            style={{ marginTop: "2rem" }}
          >
            {widget.cta.text}
          </Button>
        ) : (
          <Button
            variant="white"
            to={`/customers/`}
            style={{ marginTop: "2rem" }}
          >
            See customer stories
          </Button>
        )}
      </Content>
    </CardWrapper>
  )
}

export const PartnersFragment = graphql`
  fragment PartnersWidget on ContentfulPartnersWidget {
    title
    logos {
      ...contentfulImageGatsby
    }
    logoResizes
    cta {
      __typename
      ...ctaFragment
    }
  }
`
