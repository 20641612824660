import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"
import { MobileMediaQuery } from "../../styles/constants"
import { IWidgetProps, WidgetHero } from "../../types/widget"
import Card from "../card"
import Typography, { TypographyMarkdown } from "../typography"
import Img from "gatsby-image"
import { HoverButton } from "../button"
import colors from "../../styles/colors"
import { Form } from "../Form"
import Link from "../link"

const Wrapper = styled(Card)<{ isSmall: boolean }>`
  max-width: 1200px;
  height: ${p => (p.isSmall ? 600 : 768)}px;

  ${MobileMediaQuery} {
    height: 100%;
  }
`

const FullWidthAlignment = styled.div<{ hasImage: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${p => (p.hasImage ? "space-between" : "center")};
  text-align: ${p => (p.hasImage ? "left" : "center")};

  ${MobileMediaQuery} {
    flex-direction: column-reverse;
    align-items: center;
  }
`

const Left = styled.div<{ hasRightComponent: boolean }>`
  text-align: ${p => (p.hasRightComponent ? "left" : "center")};
  max-width: ${p => (p.hasRightComponent ? "500px" : "100%")};

  display: flex;
  flex-direction: column;
  justify-content: ${p =>
    p.hasRightComponent ? "flex-start" : "space-between"};
  align-items: ${p => (p.hasRightComponent ? "flex-start" : "center")};

  padding-right: 3rem;

  ${MobileMediaQuery} {
    text-align: center;
    margin-bottom: 3rem;
    align-items: center;
    padding-right: 0;
  }
`

const Title = styled(Typography)``

const Markdown = styled(TypographyMarkdown)`
  margin-top: 1rem;
`

const Right = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${MobileMediaQuery} {
    width: 100%;
    margin-bottom: 2rem;
    justify-content: center;
  }
`

const Button = styled(HoverButton)`
  width: 330px;
  height: 90px;
  border-radius: 1000px;
  justify-content: center;
  font-weight: 400;
  text-transform: none;
`

export const HeroWidget = ({
  widget,
}: IWidgetProps<WidgetHero>): JSX.Element => {
  const { type, variant, title, description, cta, image, form } = widget
  let rightContent = null
  if (image) {
    rightContent = (
      <Img fluid={image.fluid} style={{ flex: 1, borderRadius: 16 }} />
    )
  } else if (form) {
    rightContent = (
      <Form
        title={form.title}
        from={title}
        inputFields={form.inputFields}
        ctaButtonText={form.ctaButtonText}
        // TODO: [type] this will not be used atm as we only have one type of form
        // depending on the type we can select what type of onSubmit function we should have
        type={form.type}
      />
    )
  }

  return (
    <Wrapper
      variant={variant}
      noMinHeight
      isSmall={type === 2}
      style={{
        width: "100vw",
        maxWidth: "100vw",
        borderRadius: 0,
        marginLeft: "-4px",
        marginRight: "-4px",
      }}
    >
      <FullWidthAlignment hasImage={Boolean(image)}>
        <Left hasRightComponent={Boolean(image || form)}>
          <Title
            variant={form ? "h2" : "h1"}
            as="h1"
            style={{
              color: variant === "lightpurple" && colors.primary,
            }}
          >
            {title}
          </Title>
          <Markdown
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
          {!form && cta && (
            <Link
              to={cta.type === "Internal link" ? `/${cta.url}/` : cta.url}
              style={{ textDecoration: "none", marginTop: "4rem" }}
            >
              <Button variant={variant} ghost>
                {cta.text || "Click me"}
              </Button>
            </Link>
          )}
        </Left>
        {rightContent && <Right>{rightContent}</Right>}
      </FullWidthAlignment>
    </Wrapper>
  )
}

export const HeroWidgetFragment = graphql`
  fragment HeroWidget on ContentfulHeroWidget {
    title
    variant
    description {
      childMarkdownRemark {
        html
      }
    }
    cta {
      __typename
      ...ctaFragment
    }
    image {
      ...imageFragment
    }
    form {
      title
      inputFields {
        placeholder
        key
        type
        values {
          dropdown
        }
        helpText
      }
      ctaButtonText
      type
    }
  }
`
