import styled from "@emotion/styled"
import { graphql } from "gatsby"
import React from "react"
import { IWidgetProps, WidgetFullWidthText } from "../../types/widget"
import { HoverButton } from "../button"
import Card from "../card"
import Link from "../link"
import { TypographyMarkdown, typographyStyles } from "../typography"

const Button = styled(HoverButton)`
  ${typographyStyles.bodyLarge}
  width: 330px;
  height: 90px;
  border-radius: 1000px;
  justify-content: center;
  text-transform: none;
`

export const FullWIdthTextWidget = ({
  widget,
}: IWidgetProps<WidgetFullWidthText>): JSX.Element => (
  <Card variant="white" center>
    <TypographyMarkdown
      dangerouslySetInnerHTML={{ __html: widget.text.childMarkdownRemark.html }}
    />
    <Link
      to={
        widget.cta.type === "Internal link"
          ? `/${widget.cta?.url}/`
          : widget.cta?.url
      }
      style={{ textDecoration: "none", marginTop: "4rem" }}
    >
      <Button variant="primary">{widget.cta?.text || "Click me"}</Button>
    </Link>
  </Card>
)

export const FullWidthTextWidgetFragment = graphql`
  fragment FullWidthTextWidget on ContentfulFullWidthTextWidget {
    title
    text {
      childMarkdownRemark {
        html
      }
    }
    cta {
      ...ctaFragment
    }
  }
`
