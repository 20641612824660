import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import colors from "../../styles/colors"
import { BORDER_RADIUS_MEDIUM, MobileMediaQuery } from "../../styles/constants"
import { IWidgetProps, WidgetCardPreview } from "../../types/widget"
import Card from "../card"
import Link from "../link"
import Typography, { TypographyMarkdown, typographyStyles } from "../typography"

const Wrapper = styled(Card)``
const CardPreviews = styled.div`
  display: flex;
  margin-top: 4rem;

  ${MobileMediaQuery} {
    flex-direction: column;
  }
`
const ImageWrapper = styled.div`
  height: 230px;
  display: flex;
`
const LinkWrapper = styled(Link)<{ isNotFirst: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  text-decoration: none;
  color: ${colors.darkgrey};
  margin-left: ${p => (p.isNotFirst ? 30 : 0)}px;

  overflow: hidden;
  border-radius: ${BORDER_RADIUS_MEDIUM}px;
  background-color: ${colors.lightgrey};

  ${MobileMediaQuery} {
    max-width: 500px;
    margin-left: 0;
    margin-top: ${p => (p.isNotFirst ? 30 : 0)}px;
  }
`

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 280px;
  padding: 40px;
`

const LogoWrapper = styled.div``

const ExcerptMarkdown = styled(TypographyMarkdown)`
  height: 130px;
  overflow: hidden;
  p {
    ${typographyStyles.bodySmall};
  }
`

export const CardPreviewWidget = ({
  widget,
}: IWidgetProps<WidgetCardPreview>): JSX.Element => (
  <Wrapper
    variant="white"
    style={{ color: colors.darkgrey }}
    noMinHeight
    center
  >
    <Typography variant="h3">{widget.title}</Typography>
    <CardPreviews>
      {widget.cardPreviews?.map((story, index) => (
        <LinkWrapper
          isNotFirst={index !== 0}
          to={`/customers/${story.slug}/`}
          disabled={story.__typename === "ContentfulCardPreview"}
        >
          <ImageWrapper>
            <GatsbyImage
              image={story.image?.gatsbyImageData || ""}
              alt={`Product image of ${
                story.__typename === "ContentfulSuccessStory"
                  ? story.companyName
                  : story.title
              }`}
            />
          </ImageWrapper>
          <Bottom>
            <div>
              <Typography variant="subtitleLarge">
                {story.__typename === "ContentfulSuccessStory"
                  ? `${story.companyName} + Mavenoid`
                  : story.title}
              </Typography>
              <ExcerptMarkdown
                dangerouslySetInnerHTML={{
                  __html: story.excerpt?.childMarkdownRemark.html || "",
                }}
              />
            </div>
            {story.__typename === "ContentfulSuccessStory" && (
              <LogoWrapper>
                <GatsbyImage
                  image={story.logo?.gatsbyImageData || ""}
                  alt={`logo of ${story.companyName}`}
                />
              </LogoWrapper>
            )}
          </Bottom>
        </LinkWrapper>
      ))}
    </CardPreviews>
  </Wrapper>
)

export const CardPreviewWidgetFragment = graphql`
  fragment CardPreviewWidget on ContentfulCardPreviewWidget {
    title
    cardPreviews {
      __typename
      ... on ContentfulCardPreview {
        title
        image {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
        excerpt {
          childMarkdownRemark {
            html
          }
        }
      }
      ... on ContentfulSuccessStory {
        companyName
        slug
        excerpt {
          childMarkdownRemark {
            html
          }
        }
        image: productIntroPicture {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
        logo {
          gatsbyImageData(placeholder: TRACED_SVG, height: 25)
        }
      }
    }
  }
`
