import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import colors from "../../styles/colors"
import { IWidgetProps, WidgetVerticalText } from "../../types/widget"
import { HoverButton } from "../button"
import Card from "../card"
import Link from "../link"
import Typography, { TypographyMarkdown, typographyStyles } from "../typography"

const Wrapper = styled(Card)``

const Button = styled(HoverButton)`
  ${typographyStyles.bodyLarge}
  width: 330px;
  height: 90px;
  border-radius: 1000px;
  justify-content: center;
  text-transform: none;
`

const Markdown = styled(TypographyMarkdown)`
  max-width: 680px;
`

export const VerticalTextWidget = ({
  widget,
}: IWidgetProps<WidgetVerticalText>): JSX.Element => (
  <Wrapper variant="white" noMinHeight center>
    <Typography
      variant="h1"
      style={{ marginBottom: "1rem", color: colors.darkpurple, maxWidth: 680 }}
    >
      {widget.title}
    </Typography>
    <Markdown
      dangerouslySetInnerHTML={{
        __html: widget.description.childMarkdownRemark.html,
      }}
    />
    {widget.cta ? (
      <Link
        to={
          widget.cta.type === "Internal link"
            ? `/${widget.cta?.url}/`
            : widget.cta?.url
        }
        style={{
          textDecoration: "none",
          marginTop: "4rem",
          marginBottom: "4rem",
        }}
      >
        <Button variant="white" ghost>
          {widget.cta?.text || "Click me"}
        </Button>
      </Link>
    ) : (
      <div style={{ marginBottom: "4rem" }} />
    )}
    <GatsbyImage image={widget.image?.gatsbyImageData} alt="image" />
  </Wrapper>
)

export const VerticalTextWidgetFragment = graphql`
  fragment VerticalTextWidget on ContentfulVerticalTextWidget {
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    cta {
      ...ctaFragment
    }
    image {
      gatsbyImageData(placeholder: TRACED_SVG)
    }
  }
`
