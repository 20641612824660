import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import colors from "../../styles/colors"
import {
  BORDER_RADIUS_MEDIUM,
  MobileMediaQuery,
  MOBILE_PADDING_HORIZONTAL,
  MOBILE_PADDING_VERTICAL,
  PADDING_HORIZONTAL,
} from "../../styles/constants"
import { IWidgetProps, WidgetFeature } from "../../types/widget"
import { HoverButton } from "../button"
import Card from "../card"
import Link from "../link"
import Typography, { TypographyMarkdown } from "../typography"

const Wrapper = styled(Card)<{ isReverseOrder: boolean; isCTAType: boolean }>`
  flex-direction: row;
  padding: 0;

  flex-direction: ${p => (p.isReverseOrder ? "row-reverse" : "row")};

  ${MobileMediaQuery} {
    flex-direction: ${p => (p.isCTAType ? "column-reverse" : "column")};
    height: 100%;
    padding: 0;
  }
`

const Button = styled(HoverButton)`
  width: 330px;
  height: 50px;
  border-radius: 1000px;
  justify-content: center;
  font-weight: 400;
  text-transform: none;
`

const Left = styled.div<{ isVerticalCenter: boolean }>`
  flex: 5;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${p => (p.isVerticalCenter ? "center" : "space-between")};

  padding: ${PADDING_HORIZONTAL}px;

  ${MobileMediaQuery} {
    padding-left: ${MOBILE_PADDING_HORIZONTAL}px;
    padding-right: ${MOBILE_PADDING_HORIZONTAL}px;
  }

  ${MobileMediaQuery} {
    padding-top: ${MOBILE_PADDING_VERTICAL}px;
    padding-bottom: ${MOBILE_PADDING_VERTICAL}px;
  }
`
const Right = styled.div`
  flex: 7;
  width: 100%;
`

type FeatureCardType = "CTA" | "TEXT"

export const FeatureWidget = ({
  widget,
}: IWidgetProps<WidgetFeature>): JSX.Element => {
  let type: FeatureCardType = "TEXT"
  if (widget.overline && widget.cta) type = "CTA"

  return (
    <Wrapper
      variant={widget.backgroundColor || "lightgrey"}
      isFixedHeight
      isCTAType={type === "CTA"}
      style={{ borderRadius: `${BORDER_RADIUS_MEDIUM}px`, overflow: "hidden" }}
      isReverseOrder={widget.orientation === "right"}
    >
      <Left isVerticalCenter={type === "CTA"}>
        {type === "CTA" && (
          <Typography
            variant="h6"
            style={{ marginBottom: "1rem", color: colors.primary }}
          >
            {widget.overline}
          </Typography>
        )}
        <Typography
          variant={type === "TEXT" ? "h1" : "h3"}
          style={{
            marginBottom: "1rem",
            color: type === "TEXT" ? colors.primary : colors.darkgrey,
          }}
        >
          {widget.title}
        </Typography>
        {type === "TEXT" && (
          <TypographyMarkdown
            dangerouslySetInnerHTML={{
              __html: widget.description?.childMarkdownRemark.html ?? "",
            }}
            style={{
              color: colors.darkgrey,
            }}
          />
        )}
        {type === "CTA" && (
          <Link
            to={
              widget.cta?.type === "Internal link"
                ? `/${widget.cta.url}/`
                : widget.cta?.url
            }
            style={{
              textDecoration: "none",
              marginTop: "1rem",
            }}
          >
            <Button variant="white" ghost>
              {widget.cta?.text || "Click me"}
            </Button>
          </Link>
        )}
      </Left>
      <Right>
        <GatsbyImage image={widget.image.gatsbyImageData} alt="image" />
      </Right>
    </Wrapper>
  )
}

export const FeatureWidgetFragment = graphql`
  fragment FeatureWidget on ContentfulFeatureWidget {
    title
    orientation
    backgroundColor
    overline
    description {
      childMarkdownRemark {
        html
      }
    }
    cta {
      ...ctaFragment
    }
    image {
      gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
`
